import React, { Component, useState, useEffect } from "react";
import Tag from "./Tag";
import update from "immutability-helper";
import styled from "styled-components";
import { connect } from "react-redux";

import { findTags } from "./utils.js";
import {
  SET_DIMENSIONS,
  SET_MULTIPLE_DIMENSIONS,
  UPDATE_DIMENSIONS
} from "../../Constants/actionTypes.js";
import { useDrop } from 'react-dnd'

const mapStateToProps = state => ({
  selections: state.selections
});

const mapDispatchToProps = dispatch => ({
  setDimensions: payload => dispatch({ type: SET_DIMENSIONS, payload }),
  setMultipleDimensions: payload => dispatch({ type: SET_MULTIPLE_DIMENSIONS, payload }),
  updateDimensions: payload => dispatch({ type: UPDATE_DIMENSIONS, payload })
});

const Wrapper = styled.div`
  padding: 15px 20px;
  text-align: left;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding: 10px 0px;
  }
`;

const CustomLabel = styled.label`
  color: #999573;
  padding-right: 20px;
  min-width: 70px;
  opacity: 0.5;
`;

const Container = styled.div`
  background: #fcfcfa;
  mix-blend-mode: normal;
  border: 0.8px solid rgba(170, 170, 170, 0.418379);
  box-shadow: inset 2px 2px 4px rgba(126, 137, 95, 0.189603);
  border-radius: 6.4px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px 0px;
`;

const Tags = (props) => {

  const [dragIndex, setDragIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);

  const { dimensionType, isDisabled, label, selections } = props;
  const [tags, setTags] = useState(findTags(dimensionType, selections))
  const isMobile = window.innerWidth <= 1000;

  useEffect(() => {
    const updatedTags = findTags(dimensionType, selections);
    setTags(updatedTags);
  }, [props])

  // const tags = findTags(dimensionType, selections);

  // const findTagsFunc = (props, dragElementSourceType, draggableElement) => {
  //   const { dimensionType, selections } = props;
  //   const filters = selections.dimensions.filters;
  //   let modified_filters;

  //   if (
  //     dragElementSourceType === "filters" &&
  //     dimensionType !== dragElementSourceType
  //   ) {
  //     const draggableElementID = draggableElement["id"];
  //     modified_filters = filters.filter(data_point => {
  //       if (data_point.id !== draggableElementID) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });
  //   } else {
  //     modified_filters = filters;
  //   }

  //   let tags = props.selections.dimensions[dimensionType];

  //   return { tags, modified_filters };
  // };

  // const moveTag = (
  //   dragIndex,
  //   hoverIndex,
  //   dragElementSourceType,
  //   draggableElement
  // ) => {
  //   const dimension_type = props.dimensionType;
  //   let selections_map = {};
  //   const { tags, modified_filters } = findTagsFunc(
  //     props,
  //     dragElementSourceType,
  //     draggableElement
  //   );
  //   const dragTag = tags[dragIndex];

  //   const modifiedTags = update(tags, {
  //     $splice: [[dragIndex, 1], [hoverIndex, 0, dragTag]]
  //   });

  //   setDragIndex(dragIndex)
  //   setHoverIndex(hoverIndex)
  //   // this.setState({
  //   //   dragIndex,
  //   //   hoverIndex
  //   // });

  //   if (
  //     dragElementSourceType === "filters" &&
  //     dimension_type !== dragElementSourceType
  //   ) {
  //     selections_map["filters"] = modified_filters;
  //   }

  //   selections_map[dimension_type] = modifiedTags;

  //   props.setMultipleDimensions({ selections_map: selections_map });
  // };

  const renderTag = (props, tag, index) => {
    const dimension_type_source = props.dimensionType;
    const updatedTag = {
      ...tag,
      custom_key: tag.id,
      dimension_type_source: dimension_type_source,
      type: "tag"
    }

    return (
      <Tag
        dataset_id={tag.dataset_id}
        id={tag.id}
        name={tag.name}
        text={tag.name}

        item={updatedTag}
        index={index}
        custom_key={tag.id}
        // moveTag={moveTag}
        key={index}
        dimension_type_source={dimension_type_source}
      />
    );
  };

  const [collectedProps, drop] = useDrop(() => ({
    accept : "tag",
    collect: (monitor) => {
      return {
        hovered: monitor.isOver(),
        item: monitor.getItem()
      };
    },
    drop: (item, monitor) => {
      const element = monitor.getItem();
      const { dimension_type_source } = element;
      const dimension_type_droppable = props.dimensionType;
      let dimension_type_draggable = dimension_type_source;
  
      if (dimension_type_source !== dimension_type_droppable) {
        if (
          dimension_type_draggable !== "filters" &&
          dimension_type_droppable !== "filters"
        ) {
          props.updateDimensions({
            dimension_type: dimension_type_droppable,
            selections: [element],
            operation_type: "add"
          });
          props.updateDimensions({
            dimension_type: dimension_type_draggable,
            selections: [element],
            operation_type: "remove"
          });
        }
  
        if (dimension_type_draggable === "filters") {
          // only remove, don't add
          props.updateDimensions({
            dimension_type: dimension_type_draggable,
            selections: [element],
            operation_type: "remove"
          });

          props.updateDimensions({
            dimension_type: dimension_type_droppable,
            selections: [element],
            operation_type: "add"
          });
        }
  
        if (dimension_type_droppable === "filters") {
          // don't remove, only add
          props.updateDimensions({
            dimension_type: dimension_type_droppable,
            selections: [element],
            operation_type: "add"
          });

          props.updateDimensions({
            dimension_type: dimension_type_draggable,
            selections: [element],
            operation_type: "remove"
          });
        }
      }
    }
  }))

  return (
    <Wrapper>
      { 
        isMobile ? null : <CustomLabel>{label}</CustomLabel>
      }
      <Container
        isDisabled={isDisabled}
        ref={drop}
      >
        {
          isDisabled ? 
          null
            : 
          tags.map((tag, index) => renderTag(props, tag, index))
        }
      </Container>
    </Wrapper>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tags);
