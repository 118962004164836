import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { IoIosCloseCircle } from "react-icons/io";
import ModalContainer from './ModalContainer.js';

import { SET_CONFIGURATION_WIDGET, SET_MODAL_VISIBILITY } from '../../Constants/actionTypes';

const mapStateToProps = state => ({
    configurations_widget : state.table_controls.configurations_widget,
    show : state.table_controls.show
});

const mapDispatchToProps = dispatch => ({
    setConfigurationWidget : (payload) => dispatch({ type : SET_CONFIGURATION_WIDGET, payload }),
    setModalVisibility : (payload) => dispatch({ type : SET_MODAL_VISIBILITY, payload })
});

const Header = styled.div`
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #whitesmoke;

`;

const Heading = styled.div`
    font-weight: 600;
    color: #959595;
    font-size : 15px;
`;

const Conditionals = styled.div`
`;

const DropDownConditional = styled.div`
    padding: 10px 0px;
    text-align: left;
`;

const ConditionalHeading = styled.div`
    font-size : 16px;
    padding : 8px 0 18px 0px;
    float: left;
    color: #959595;
    font-weight: 500px;
`;

const CustomSelect = styled.select`
    font-size: 14px;
    padding: 2px;
    height: 35px;
    cursor: pointer;
    outline: none;
    color: #7D7D7D; 
    width: 100%;
    border: 1px solid #DEDEDE;
    background: #fff;
    border-radius: 5px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px 15px 0px;
`;

const ApplyButton = styled.button`
    background-color: #4a86e2;
    color: white;
    font-size: 13px;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    border : none;
`;

const ConfigurationWidget = (props) => {
    const { configurations_widget, show } = props;

    const { _appearance, number_format, number_decimal, show_conditional_filter, show_conditional_format, show_toggle_columns, show_download } = configurations_widget;
    const [appearance, setAppearance] = useState(_appearance)
    const [numberFormat, setNumberFormat] = useState(number_format)
    const [numberDecimal, setNumberDecimal] = useState(number_decimal)
    const [showConditionalFilter, setConditionalFilter] = useState(show_conditional_filter)
    const [showConditionalFormat, setConditionalFormat] = useState(show_conditional_format)
    const [showToggleColumns, setToggleColumns] = useState(show_toggle_columns)
    const [showDownload, setShowDownload] = useState(show_download)

    const handleSelectOption = (event, callbackSetFunction) => {
        const conditional_option = event.target.value;
        callbackSetFunction(conditional_option)
        event.stopPropagation();
    }

    const handleCheckbox = (event, callbackSetFunction) => {
        const isChecked = event.target.checked;
        callbackSetFunction(isChecked)
        event.stopPropagation();
    }

    const handleCloseButton = (event)=>{
        const showConfigurationWidget = show === "configurations_widget";

        props.setModalVisibility({
            control_type : "configurations_widget",
            show : !showConfigurationWidget
        });
    }

    const handleApplyButton = () => {
        props.setConfigurationWidget({ 
            appearance : appearance, 
            number_format : numberFormat,
            number_decimal : numberDecimal,
            show_conditional_filter : showConditionalFilter,
            show_conditional_format : showConditionalFormat,
            show_toggle_columns : showToggleColumns,
            show_download: showDownload
        });
    }

    return (
    <ModalContainer padding={"10px"} right={'0'} top={'45px'}>
        <Header>
            <Heading>
                Table Configuration
            </Heading>
            <IoIosCloseCircle style={{"cursor" : "pointer", "fontSize" : "18px","color":"#BEA281","margin-right": "5px"}} onClick={handleCloseButton} />
        </Header>
        <Conditionals>
            <DropDownConditional>
                <ConditionalHeading>Appearance</ConditionalHeading>
                <CustomSelect onChange={(event) => { handleSelectOption(event, setAppearance) }} value={appearance}>
                    <option value={"0"}>Standard</option>
                    <option value={"1"}>Blue</option>
                    <option value={"2"}>Green</option>
                    <option value={"3"}>Red</option>
                    <option value={"4"}>Brown</option>
                    <option value={"5"}>Grey</option>
                </CustomSelect>
            </DropDownConditional>
            <DropDownConditional>
                <ConditionalHeading>Number Format</ConditionalHeading>
                <CustomSelect onChange={(event) => { handleSelectOption(event, setNumberFormat) }} value={numberFormat}>
                    <option value={"0"}>General</option>
                    <option value={"1"}>Currency</option>
                    <option value={"2"}>Percent</option>
                    <option value={"3"}>Delimiter</option>
                </CustomSelect>
            </DropDownConditional>
            <DropDownConditional>
                <ConditionalHeading>Number Decimal</ConditionalHeading>
                <CustomSelect onChange={(event) => { handleSelectOption(event, setNumberDecimal) }} value={numberDecimal}>
                    <option value={"0"}>0</option>
                    <option value={"1"}>1</option>
                    <option value={"2"}>2</option>
                    <option value={"3"}>3</option>
                    <option value={"4"}>4</option>
                </CustomSelect>
            </DropDownConditional>
            <DropDownConditional>
                <input onChange={(event) => { handleCheckbox(event, setConditionalFilter) }} type="checkbox" id="showConditionalFilter" name="showConditionalFilter" value={showConditionalFilter} checked={showConditionalFilter} />
                <label for="showConditionalFilter"> Show Conditional Filter</label>
            </DropDownConditional>
            <DropDownConditional>
                <input onChange={(event) => { handleCheckbox(event, setConditionalFormat) }} type="checkbox" id="showConditionalFormat" name="showConditionalFormat" value={showConditionalFormat} checked={showConditionalFormat} />
                <label for="showConditionalFormat"> Show Conditional Format</label>
            </DropDownConditional>
            <DropDownConditional>
                <input onChange={(event) => { handleCheckbox(event, setToggleColumns) }} type="checkbox" id="showToggleColumns" name="showToggleColumns" value={showToggleColumns} checked={showToggleColumns} />
                <label for="showToggleColumns"> Show Toggle Columns</label>
            </DropDownConditional>
            <DropDownConditional>
                <input onChange={(event) => { handleCheckbox(event, setShowDownload) }} type="checkbox" id="showDownload" name="showDownload" value={showDownload} checked={showDownload} />
                <label for="showDownload"> Show Download</label>
            </DropDownConditional>
        </Conditionals>
        <ButtonContainer>
            <ApplyButton onClick={handleApplyButton}>
                Apply
            </ApplyButton>
        </ButtonContainer>
    </ModalContainer>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationWidget);
