export const SET_DATAFLOW = 'SET_DATAFLOW';
export const REMOVE_DATAFLOW = 'REMOVE_DATAFLOW';
export const UPDATE_DATAFLOW = 'UPDATE_DATAFLOW';

export const SET_DIMENSIONS = 'SET_DIMENSIONS';
export const SET_MULTIPLE_DIMENSIONS = 'SET_MULTIPLE_DIMENSIONS';
export const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS';

export const SET_DIMENSION_VALUES = 'SET_DIMENSION_VALUES';
export const SET_FILTERS = 'SET_FILTERS';

export const REMOVE_DATAFLOW_SELECTIONS = 'REMOVE_DATAFLOW_SELECTIONS';
export const UPDATE_DATA_PACKAGE = 'UPDATE_DATA_PACKAGE';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// DATASET Actions ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const SET_DATASET = 'SET_DATASET';
export const SET_DATASET_ID = 'SET_DATASET_ID';
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Table Controls Actions ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// CONDITIONAL FORMAT + CONDITIONAL FILTERS
export const SET_CONDITIONAL_FILTER = 'SET_CONDITIONAL_FILTER';
export const SET_CONDITIONAL_FORMAT = 'SET_CONDITIONAL_FORMAT';
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
// Table Configurations Widget
export const SET_CONFIGURATION_WIDGET = 'SET_CONFIGURATION_WIDGET';
// Views Selection
export const SET_SELECTION_VIEW= 'SET_SELECTION_VIEW';
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~