import { combineReducers } from 'redux';
import { createReduxHistoryContext } from "redux-first-history";

//reducers
import selections from './selections';
import dataset from './dataset';
import datapackage from './datapackage';
import TableControls from './TableControls';

const reducer = (history) => 
{
  const { routerReducer } = createReduxHistoryContext({ history: history });
  return combineReducers({
    router: routerReducer,
    selections,
    dataset,
    table_controls : TableControls,
    datapackage,
  })
}

export default reducer;
