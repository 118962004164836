import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import  { configureStore, history } from './store';
import App from './App';

const store = configureStore();

class ConnectedApp extends Component{
 	render() {
	 	return (
	 		<Provider store={store}>
			    <Router history = {history}>
			      <Routes>
			        <Route path="/" element={<App />} />
			      </Routes>
			    </Router>
			</Provider>
		)
	}
}

export default ConnectedApp;