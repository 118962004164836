import React, { Component } from 'react';
import styled from 'styled-components';
import LeftBar from './LeftBar/index.js';
import RightBar from './RightBar/index.js';

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

class Dashboard extends Component{
	render(){
		return (
			<Container>
                <LeftBar />
                <RightBar />
			</Container>
		)
	}
}

export default Dashboard;
