import React, { useRef, useEffect } from 'react';

const findTags = (dimension, selections) => {
  let tags = selections.dimensions[dimension];
  if (dimension !== "filters") {
    const filters = selections.dimensions.filters.map(x => x.id);
    tags = tags.filter(x => !filters.includes(x.id));
  }
  return tags;
};

const useOutsideClick = (callback) => {
  const ref = useRef();
  useEffect(() => {
     const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
           callback();
        }
     };
     document.addEventListener('click', handleClick, true);
     return () => {
        document.removeEventListener('click', handleClick, true);
     };
  }, [ref]);
  return ref;
};

export { findTags, useOutsideClick };
