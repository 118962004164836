import React, { Component } from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';
import getColumns from './Columns.js';
import { connect } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import DataFrame from 'dataframe-js';
import { filterDataframe } from '../../API.js';

import { UPDATE_DATA_PACKAGE } from '../../Constants/actionTypes.js';
import { checkConditional, getConfigurationRowColor, getConfigurationHeaderColor } from '../../Constants/ConditionalOptions.js';

const mapStateToProps = state => ({
    selections : state.selections,
    dataset : state.dataset,
    table_controls : state.table_controls,
    view_selection: state.table_controls.view_selection
});

const mapDispatchToProps = dispatch => ({
    updateDataPackage : (payload) => dispatch({ type : UPDATE_DATA_PACKAGE, payload }),
});

const TableContainer = styled.div`
    margin: 10px 0px;
    padding: 10px 0px;
	background: white;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.0432965);
    display:${props => props.isActive ? 'unset' : 'none'};
    width: 100%;
`;


const ReactTable = ({ columns, data }) => {
    // Use the useTable Hook to send the columns and data to build the table
    const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      rows, // rows for the table based on the data passed
      prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
    } = useTable({
      columns,
      data
    });
  
    /* 
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
}


class Table extends Component{

	constructor(props) {
        super(props);

		this.state = {
			isLoading : true,
            data : [],
            columns : []
        };
    }

    findUniqueColumns = (data, columns, filter_ids, table_controls) => {
        return getColumns(data, columns, filter_ids, table_controls)
    }

    findColumnDescriptions = () => {
        const descriptions = {};
        const { rows, columns } = this.props.selections.dimensions;
        const dimensions = rows.concat(columns);
        dimensions.forEach((dimension) => {
            const { dataset_id, description, id } = dimension;
            if (!descriptions.hasOwnProperty(dataset_id)){
                descriptions[dataset_id] = {};
            }
            descriptions[id] = description;
        });

        return descriptions;
    }

    // while looping on each dataset, also check if the columns in dataset have the hidden count 
    // equal to the number of total rows. If thats true hide that column where the same condition 
    // is met.

    // Hence we need to update the column headers as well. Just need to filter out the column whose id
    // is equivalent to the one for the matching column conditions.

    applyConditionalFilter = (table_controls, dataset, unique_columns) => {
        const { conditional_filter } = table_controls;
        const { hide_conditionals, conditional_option, conditional_value_a, conditional_value_b } = conditional_filter;
        const { hide_zeroes, hide_empty_fields, hide_non_numerics } = hide_conditionals;

        // This object stores the count of cells to be hidden for a particular header/column.
        const headers_hidden_condition = {};

        // These are the different hidden condition types. The generic_condition is for all the
        // conditions present in the dropdown.
        const hidden_condition_types = ["zeroes", "empty_fields", "non_numerics", "generic_condition"];

        const headers = this.state.columns.map((column) => {
            const header_id = column.accessor;

            hidden_condition_types.forEach((hidden_condition_type) => {
                // initializing the object.
                if (!headers_hidden_condition.hasOwnProperty(hidden_condition_type)){
                    headers_hidden_condition[hidden_condition_type] = {};
                };

                headers_hidden_condition[hidden_condition_type][header_id] = 0;
            });

            return column.accessor
        });

        const filtered_dataset = dataset.filter((each_row) => {
            headers.forEach((header) => {
                const data_point = each_row[header];

                // Check hide_zeroes conditional if its true
                if (hide_zeroes){
                    if (data_point === "0"){
                        headers_hidden_condition["zeroes"][header] = headers_hidden_condition["zeroes"][header] + 1;
                    }
                }

                // Check hide_zeroes conditional if its true
                if (hide_empty_fields){
                    if (data_point === undefined || data_point === data_point.length){
                        headers_hidden_condition["empty_fields"][header] = headers_hidden_condition["empty_fields"][header] + 1;
                    }
                }

                // Check hide_zeroes conditional if its true
                if (hide_non_numerics){
                    if (isNaN(parseInt(data_point))){
                        headers_hidden_condition["non_numerics"][header] = headers_hidden_condition["non_numerics"][header] + 1;
                    }
                }

                // These are all the dropdown conditions for the case "generic_conditions"
                if (checkConditional(conditional_option, data_point, conditional_value_a, conditional_value_b)){
                    headers_hidden_condition["generic_condition"][header] = headers_hidden_condition["generic_condition"][header] + 1;
                }
            })

            const test_dataset_values = Object.values(each_row);
            let overall_filter_condition;
            
            // Check hide_zeroes conditional if its true
            if (hide_zeroes){
                const hide_zeroes_conditional = test_dataset_values.some((data_point) => {
                    return data_point === "0"
                });

                overall_filter_condition = overall_filter_condition || hide_zeroes_conditional;
            }

            // Check hide_empty_fields conditional if its true
            if (hide_empty_fields){
                const hide_empty_fields_conditional = test_dataset_values.some((data_point) => {
                    return ( data_point === undefined || data_point === data_point.length )
                });

                overall_filter_condition = overall_filter_condition || hide_empty_fields_conditional;
            }

            // Check hide_non_numerics conditional if its true.
            if (hide_non_numerics){
                const hide_non_numerics_conditional = test_dataset_values.some((data_point) => {
                    return isNaN(parseInt(data_point))
                });

                overall_filter_condition = overall_filter_condition || hide_non_numerics_conditional;
            }

            // Check if there is any conditional option filter being applied.
            const check_option_conditional = test_dataset_values.some((data_point) => {

                // Over here handle the condition when, the number is a string and the
                // row should disappear if every other cell satisfies the hide condition

                // console.log('!parseFloat(data_point)', data_point, !parseFloat(data_point))
                // if (!parseFloat(data_point)){
                //     return true
                // }
                // else{
                //     return checkConditional(conditional_option, data_point, conditional_value_a, conditional_value_b)
                // }
                return checkConditional(conditional_option, data_point, conditional_value_a, conditional_value_b)
            });

            overall_filter_condition = overall_filter_condition || check_option_conditional;

            return !overall_filter_condition
        });

        // find out the columns which need to be hidden. The hidden columns will be the one's
        // whose count for hidden cell mathces the total number of rows in the dataset.

        let hidden_columns_set = new Set();

        headers.forEach((header) => {
            hidden_condition_types.forEach((hidden_condition_type) => {
                if (headers_hidden_condition[hidden_condition_type][header] === dataset.length){
                    hidden_columns_set.add(header);
                }
            });
        });

        const hidden_columns = Array.from(hidden_columns_set);

        // filter hidden columns from the columns list/ headers list here.
        let modified_unique_columns = unique_columns; 

        if (hidden_columns.length !== 0){
            modified_unique_columns = unique_columns.filter((column_data) =>{
                const column_id = column_data.accessor;
                if (hidden_columns.includes(column_id)){
                    return false
                }
                else{
                    return true
                }
            });
        }
        
        return { filtered_dataset, modified_unique_columns }
    }

	componentDidUpdate(prevProps, prevState){
        const current_dataset_id = this.props.selections.datasets.selected_id;
        const current_datasets = this.props.dataset;

        if (current_datasets.hasOwnProperty(current_dataset_id)){
            const didSelectionsChange = JSON.stringify(this.props.selections) !== JSON.stringify(prevProps.selections);

            delete this.props.table_controls.show;
            delete prevProps.table_controls.show;
    
            const didTableControlsChange = JSON.stringify(this.props.table_controls) !== JSON.stringify(prevProps.table_controls);
    
            if ( (didSelectionsChange || didTableControlsChange ) && Object.keys(this.props.dataset).length > 0) {
                this.updateData()
            }
        }
    }
    
	updateData = () => {
        const { selections, dataset, table_controls } = this.props;
        const selected_id = selections.datasets.selected_id
        const { columns, filters, values } = selections.dimensions;
        const filter_ids = [...new Set(filters.map((obj) => obj['id']))];
        const initial_unique_columns = this.findUniqueColumns(dataset, columns, filter_ids, table_controls);
        const { filtered_dataset, modified_unique_columns } = this.applyConditionalFilter(this.props.table_controls, dataset[selected_id], initial_unique_columns);
        const filtered_values = selections.filters;
        // const current_dataset = filtered_dataset[this.props.selections.datasets.selected_id];
        const current_dataset = filtered_dataset
        const unique_columns = this.findUniqueColumns(current_dataset, columns, filter_ids, table_controls);

        const column_ids = columns.map((col) => { return col["id"]});

        const df = new DataFrame(filtered_dataset, column_ids);
        // const bcd = df.filter((row) =>{
        //     return row.get('market') === 'Drones'
        // });

        const bcd = filterDataframe(df, filtered_values).toCollection();

        this.setState({
            data : bcd,
            columns : unique_columns,
            isLoading : false
        });
    }

	render(){
        const { isLoading, data, columns } = this.state;

        const { table_controls, view_selection } = this.props;
        const { configurations_widget } = table_controls;
        const { appearance } = configurations_widget;

        return (
            <TableContainer isActive={view_selection.table}>
                {
                    !isLoading ? (
                        <ReactTable columns={columns}
                            data={data}
                        />
                        // <ReactTable
                        //     data={data}
                        //     columns={columns}
                        //     minRows={data.length}

                        //     pageSize={data.length}
                        //     showPagination={false}

                        //     defaultSortDesc={true}
                        //     ref={r => (this.selectTable = r)}
                        //     getTheadThProps={() => { 
                        //         const header_color = getConfigurationHeaderColor(appearance);
                        //         return { style: { fontSize: "13px", "wordWrap": "break-word", "whiteSpace": "initial", "background" : header_color  } } }}
    
                        //     getTdProps={(row, index) => { 
                        //         let row_color;
                                
                        //         if (!index){
                        //             row_color = 'white';
                        //         }
                        //         else{
                        //             row_color = getConfigurationRowColor(appearance, index.index);
                        //         }

                        //         const row_styles = { "padding" : "0px", "height" : "24px", "backgroundColor" : row_color };
                        //         return  { 'style' : row_styles }
                        //     }}

                        //     onSortedChange ={() => {
                        //         this.updateData()
                        //     }}
                        // />
                    )
                    :
                    <ClipLoader
                        css={false}
                        sizeUnit={"px"}
                        size={100}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                }
            </TableContainer>
        )
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Table);