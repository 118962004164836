import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useDrag } from 'react-dnd'
import { getConfigurationHeaderColor } from '../../Constants/ConditionalOptions.js';
import { useOutsideClick } from './utils.js'

import FilterEditor from "./FilterEditor";

const Container = styled.div`
  margin: 2px 6px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomLabel = styled.div`
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 14.4px;
  font-weight: bold;
  letter-spacing: 0.3px;
  opacity: ${props => (props.isDragging ? "0" : "1")};
  
  padding: 5px 10px 5px 10px;
  background-color: ${props => props.backgroundColor};
`;

const mapStateToProps = state => ({
  filters: state.selections.filters,
  dimension_values: state.selections.dimensions.values,
  selected_id : state.selections.datasets.selected_id,
  table_controls : state.table_controls
});

const Tag = (props) => {

  const [showFilter, setShowFilter] = useState(false);
  const { dataset_id, selected_id, id, name, text, table_controls, isDragging } = props;
  const configurations_widget = table_controls.configurations_widget;

  const handleTagClick = event => {
    setShowFilter(!showFilter)
  };

  const handleCloseEditor = () => {
    setShowFilter(false)
  };

  const findTagName = (text, dataset_id, id) => {
    const { filters, dimension_values } = props;
    let tagName = text;

    if (dimension_values.hasOwnProperty(dataset_id)) {
      const currentDimensionValues = dimension_values[dataset_id][id]["values"];
      const currentFilters = filters[dataset_id][id];
      const currentFilterValues = currentFilters.map(data_point => {
        return data_point.name;
      });

      if (currentDimensionValues.length !== currentFilters.length) {
        const trimmedFilterValues = currentFilterValues.slice(0, 2).join(", ");

        let more;
        if (currentFilterValues.length > 2) {
          more = ", ...";
          tagName = `${tagName} (${trimmedFilterValues}${more})`;
        } else {
          tagName = `${tagName} (${trimmedFilterValues})`;
        }
      }
    }

    return tagName;
  };

  const tagText = findTagName(text, dataset_id, id);

  const [collected, drag, dragPreview] = useDrag(() => ({
    type: "tag",
    item: () => {
      console.log('props item', props.item)
      return props.item
    }
  }), [props])

  const handleClickOutside = () => {
    setShowFilter(false)
  };
  
  const ref = useOutsideClick(handleClickOutside);

  return (
    <Container ref={ref}>
      {
        collected.isDragging ? (
          <CustomLabel 
            ref={dragPreview}
            onClick={handleTagClick} 
            isDragging={isDragging} 
            backgroundColor={getConfigurationHeaderColor(configurations_widget.appearance)}
          >
            {tagText}
          </CustomLabel>
        ) : (
          <CustomLabel 
            ref={drag} {...collected}
            onClick={handleTagClick} 
            isDragging={isDragging} 
            backgroundColor={getConfigurationHeaderColor(configurations_widget.appearance)}
          >
            {tagText}
          </CustomLabel>
        )
      }
      {
        showFilter ? 
        (
          <FilterEditor
            name={name}
            dataset_id={selected_id}
            id={id}
            handleCloseEditor={handleCloseEditor}
          />
        ) 
        : 
        null
      }
    </Container>
  );

}

export default connect(mapStateToProps)(Tag)