import React, { Component } from "react";
import styled from "styled-components";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import { getDataflow } from "../../API";

import Tags from "./Tags.js";
import { UPDATE_DIMENSIONS, SET_DATAFLOW, SET_DIMENSION_VALUES } from "../../Constants/actionTypes.js";

const Container = styled.div`
  border: 1px solid whitesmoke;
  border-radius: 6px;
  margin: 0px 0px 20px 0px;
  padding: 0px 10px;
  background: #ffffff;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.0432965);

  @media only screen and (max-width: 768px) {
    margin: 10px 0px 0px 0px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid whitesmoke;
  color: #bbbbbb;
`;

const IconContainer = styled.div`
  cursor: pointer;

  @media (min-width: 769px) {
    margin-right: 30px;
  }
`;

const Heading = styled.div`
  color: #bbbbbb;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 20px;
  text-align: left;

  @media (max-width: 1000px) {
    padding: 10px 0px;
    font-size: 15px;
    font-weight: bold;
    color: #999573;
    opacity: 0.5;
}
`;

const mapStateToProps = state => ({
  selections: state.selections
});

const mapDispatchToProps = dispatch => ({
  updateDimensions: payload => dispatch({ type: UPDATE_DIMENSIONS, payload }),
  setDataflow: payload => dispatch({ type: SET_DATAFLOW, payload }),
  setDimensionValues: payload => dispatch({ type : SET_DIMENSION_VALUES, payload })
});

class DimensionsTable extends Component {
  constructor(props) {
    super(props);

    const isMobile = window.innerWidth <= 1000;

    this.state = {
      isLoading: false,
      show: !isMobile
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const current_selected_id = this.props.selections.datasets.selected_id;
    const previous_selected_id = prevProps.selections.datasets.selected_id;
    if ( current_selected_id !== previous_selected_id ) {
      let dataset_id = current_selected_id;
      let operation = "add";
      this.handleDataSetChange(dataset_id, operation);
    }
  }

  handleDataSetChange = (datasetID, operation) => {
    this.datasetClicked(datasetID, operation);
  };

  datasetClicked = async (dataset_id, operation) => {
    const newDataflow = await getDataflow(dataset_id);
    let { columns, dataset, dimensions } = newDataflow;

    this.props.setDataflow({
      dataset,
      id: dataset_id
    });

    // multiple small reducers or single big reducer
    // How to handle multiple small reducers such that it doesnt block

    this.props.updateDimensions({
      dimension_type: "filters",
      selections: [],
      operation_type: operation
    });

    this.props.updateDimensions({
      dimension_type: "columns",
      selections: columns,
      operation_type: operation
    });

    this.props.setDimensionValues({
      dimensions: dimensions
    })

  };

  handleOnClick = () => {
    const show = this.state.show;

    this.setState({
      show: !show
    });
  };

  render() {
    const { isLoading, show } = this.state;
    const isMobile = window.innerWidth <= 1000;

    if (isLoading) {
      return null;
    } else {
      return (
        <Container>
          <Header>
            <Heading>Toggle Columns Visibility</Heading>
            <IconContainer onClick={this.handleOnClick}>
              {show ? (
                <FaChevronUp style={{ fontSize: "16px" }} />
              ) : (
                <FaChevronDown style={{ fontSize: "16px" }} />
              )}
            </IconContainer>
          </Header>
          {show ? (
            <DndProvider backend={HTML5Backend}>
              {
                isMobile ? ( null ) : 
                (
                  <Tags
                    label="Hidden"
                    dimensionType={"filters"}
                    isDisabled={false}
                  />
                )
              }
              <Tags
                label="Visible"
                dimensionType={"columns"}
                isDisabled={false}
              />
            </DndProvider>
          ) : null}
        </Container>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DimensionsTable);
