import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';
import ClipLoader from 'react-spinners/ClipLoader';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import { SET_DATASET_ID } from '../../Constants/actionTypes.js';

const fuseOptions = {
    shouldSort: true,
    threshold: 0.2,
    tokenize: true,
    location: 0,
    distance: 0,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
      "name"
    ]
};

const mapStateToProps = state => ({
    selections : state.selections,
    utils : state.utils
});

const mapDispatchToProps = dispatch => ({
    setDatasetId : (payload) => dispatch({ type : SET_DATASET_ID, payload })
});

const Container = styled.div`
    padding: 20px 0px;
    border-radius: 6px;

    @media only screen and (max-width: 768px) {
        padding: 0px 10px;
        background: #FFFFFF;
		box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.0432965);
		border: 1px solid whitesmoke;
    }
`;

const Row = styled.div`
    @media only screen and (max-width: 768px) {
        margin-bottom: 10px;

        ${props => !props.isShow && css`{
            display: none; 
        }`
    } 
`;

const IconContainer = styled.div`
    display: none;
    @media only screen and (max-width: 768px) {
        cursor: pointer;
        float: right;
        display: unset;
    }
`;

const Heading = styled.div`
    color : #999573;
    opacity: 0.5;
    font-size : 15px;
    text-align: left;
    padding: 10px 0px;
    @media only screen and (max-width: 768px) {
        cursor: pointer;
    }
`;

const OptionsContainer = styled.div`
    width: 100%;
    padding: 0px 10px 0px 0px;
`;

const Options = styled.div`
    width: 100%;
    font-size: 14px;
    cursor: pointer;

    background: linear-gradient(180deg, #FFFFFF 0%, #FBFBFB 100%);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(216, 212, 185, 0.506173);
    border-radius: 5px;
    
    > div:not(:last-child) {
      border-bottom: 1px solid whitesmoke;
    }
    
    > div:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    
    > div:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
`;

const Option = styled.div`
    padding : 10px 5px 10px 15px;
    display: flex;
    justify-content: space-between;
    background-image: ${props => props.isSelected ? "linear-gradient(#E8EEEF, #E8EDE5)" : "white" };
    color: ${props => props.isSelected ? "#7F85A7" : "#A7A7A7" };
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.59px;

    :hover {
        background: linear-gradient(#E8EEEF, #E8EDE5);
    }
`;

const CustomInput = styled.input`
    cursor: pointer;
`;

const SearchBar = styled.input`
    width: 75%;
    padding:10px 5px;
    padding-left: 35px;
    font-size: 14px;
    color: #616161;
    display: inline;
    
    margin-bottom:10px;
    
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-shadow: inset 2px 3px 5px rgba(126, 137, 95, 0.0640297);
    border-radius: 5px;
    
    background-image:url("magnifier.svg");
    background-repeat:no-repeat;
    background-position: 10px 10px;

    :hover, :focus{
        outline: none;
    }
`;

const SortButton = styled.span`
    content: url("filter-down.svg");
    transform: ${props => props.isSort ? "scaleY(-1)" : "none" }; 
    margin-left: 10px;
    display: inline;
    cursor: pointer;
`;

class DataSets extends Component{

	constructor(props) {
        super(props);
        
        this.state = {
            searchedResults : [],
            searchValue : "",
            isLoading : true,
            show: false,
            sort: false
        }
    }
    
    componentDidMount(){
        if (!this.props.selections.datasets.selected_id){
            this.props.setDatasetId({ selected_id : 1 });
        }
    }
    
	componentDidUpdate(prevProps){
        const newOptions = this.getOptions();
        if(JSON.stringify(newOptions) !== JSON.stringify(this.getOptions(prevProps))){
            this.setState({
                searchedResults: newOptions,
                isLoading: newOptions === null
            });
        }
    }

    getOptions(props = this.props) {
        return Object.values(props.selections[props.selectionType].options);
    }
    
    handleOnChange = (selected_id) => {
        this.props.setDatasetId({ selected_id : selected_id });
    }

    handleSearch = (event) => {
        let searchedResults;
        const searchValue = event.target.value;
        const options = this.getOptions();

		if (searchValue.length > 0){
			const fuse = new Fuse(options, fuseOptions);
            searchedResults = fuse.search(searchValue);
		}
		else{
			searchedResults = options;
        }

		this.setState({
            searchedResults : searchedResults,
            searchValue: searchValue
		});
    }

    handleSort = () => {
        const searchedResults = this.state.searchedResults
        const sort = this.state.sort;

        if(sort){
            searchedResults.sort((a,b) => (b.name > a.name) ? 1 : ( (a.name > b.name) ? -1 : 0)); 
        }else{
            searchedResults.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
         
        this.setState({
            searchedResults : searchedResults,
            sort : !sort
        })
    }

    handleOnToggle = () => {
		const show = this.state.show;
		this.setState({
			show : !show
        })
	}

	render(){  
        const { show, sort, searchValue, searchedResults, isLoading } = this.state;
        const options = this.getOptions();
        const selected_id = this.props.selections["datasets"]["selected_id"];

        return (
            <Container>
                <Heading onClick={this.handleOnToggle}><b>Datasets</b> {options ? `(${options.length})` : ""}
                    <IconContainer onClick={this.handleOnClick}>
							{
								show ? <FaChevronUp style={{"fontSize":"16px"}} /> : <FaChevronDown style={{"fontSize":"16px"}} />
                            }
					</IconContainer>
                </Heading>
                <Row isShow={show}>
                <SearchBar placeholder="Search Dataset" value={searchValue} onChange={this.handleSearch}/>
                <SortButton isSort={sort} onClick={this.handleSort}/>
                {
                    isLoading ?
                        (
                            <ClipLoader
                                css={false}
                                sizeUnit={"px"}
                                size={100}
                                color={'#123abc'}
                                loading={isLoading}
                            />
                        )
                        :
                        (
                        <OptionsContainer className="datasets">
                            <Options>
                                {
                                    searchedResults.map((searchedResult)=>{
                                        const { name , id } = searchedResult;
                                        const isSelected = selected_id === id;
                                        return (
                                            <Option isSelected={isSelected} key={id} onClick={() => { this.handleOnChange(id) }}>
                                                <label>{name}</label>
                                            </Option>
                                        )
                                    })
                                }
                            </Options>
                        </OptionsContainer>
                        )

                }
                </Row>
            </Container>
        )
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataSets);
