import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { IoIosCloseCircle } from "react-icons/io";
import ModalContainer from './ModalContainer.js';

import ColorPalette from './ColorPalette.js';
import { SET_CONDITIONAL_FORMAT, SET_MODAL_VISIBILITY } from '../../Constants/actionTypes';

const mapStateToProps = state => ({
    conditional_format : state.table_controls.conditional_format,
    show : state.table_controls.show
});

const mapDispatchToProps = dispatch => ({
    setConditionalFormat : (payload) => dispatch({ type : SET_CONDITIONAL_FORMAT, payload }),
    setModalVisibility : (payload) => dispatch({ type : SET_MODAL_VISIBILITY, payload })
});

const Header = styled.div`
    border: 1px solid whitesmoke;
    padding: 10px 0px;
    display: flex;
    justify-content: space-around;
`;

const Heading = styled.div`
    font-weight: 600;
    color: #959595;
    font-size : 14px;
    border-right: none !important;
    
`;

const Conditionals = styled.div`
    border-top: 1px solid #E9E4C8;
    border-bottom: 1px solid #E9E4C8;
    padding: 10px 10px;
`;

const ConditionalHeading = styled.div`
    font-size : 15px;
    float:left;
    padding: 8px 0 18px 0px;
    color: #959595;
    border-right: none !important;
`;

const CustomSelect = styled.select`
    font-size: 14px;
    padding: 2px;
    height: 35px;
    cursor: pointer;
    outline: none;
    color: #7D7D7D;
    width: 100%;
`;

const InputArea = styled.div`
    display: flex;
    justify-content: space-around;
    padding : 8px 4px 0px 4px;
`;

const InputContainer = styled.div`
    display: flex;
`;

const CustomLabel = styled.label`
    font-size: 14px;
    font-weight:  500;
    padding: 5px 0px;
    font-weight: 600;
    color: #959595;
    &:first-child{
        margin-right: 105px
    }
`

const LabelContainer = styled.div`
    display: flex;
    padding : 8px 4px 0px 4px;
`;

const CustomInput = styled.input`
    width: 90px;
    padding: 10px 5px;
    outline: none;
    background-color : ${props => props.disabled ? 'whitesmoke' : 'none'};
    border: 0.85px solid #DEDEDE;
    border-radius: 5px;
    font-size: 14px;
    color: #959595;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 0px 15px 0px;
`;

const ActionButton = styled.button`
    background-color: ${props => props.backgroundColor};
    color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    border : none;
`;

const ConditionFormat = (props) => {

    const { conditional_value_a, conditional_value_b, conditional_option } = props.conditional_format;
    const [inputValues, setInputValues] = useState({
        'a' : conditional_value_a,
        'b' : conditional_value_b
    })

    const [select, setSelect] = useState(conditional_option);
    const [cellColor, setCellColor] = useState('#ffffff');

    const handleSelectOption = (event) => {
        const conditional_option = event.target.value;
        setSelect(conditional_option)
        event.stopPropagation();
    }

    const handleCloseButton = (event)=>{
        const show = props.show === 'conditional_format';

        props.setModalVisibility({
            control_type : "conditional_format",
            show : !show
        });

        event.stopPropagation();
    }

    const handleInputChange = (event, input_type) => {
        const input_value = event.target.value;
        const previous_input = Object.assign({}, inputValues);
        previous_input[input_type] = input_value;
        const modified_input = previous_input;

        setInputValues(modified_input)

        event.stopPropagation();
    }

    const handleColorChange = (cell_color) => {
        setCellColor(cell_color)
    }

    const handleApplyButton = () => {
        const conditional_value_a = inputValues["a"];
        const conditional_value_b = inputValues["b"];

        const validation_for_input_a = (select !== "0" && conditional_value_a.length === 0);
        const validation_for_input_b = ( (select === "7" || select === "8") && conditional_value_b.length === 0 );

        if (validation_for_input_a || validation_for_input_b){
            if (validation_for_input_a && validation_for_input_b){
                alert("Input value empty for both A & B");
            }
            else if (validation_for_input_b){
                alert("Input value empty for B");
            }
            else{
                alert("Input value empty for A");
            }
        }
        else{
            props.setConditionalFormat({
                conditional_option : select,
                conditional_value_a : conditional_value_a,
                conditional_value_b : conditional_value_b,
                cell_color : cellColor
            })
        }
    }

    const handleClearCondition = () => {
        // this.props
    }

    return (
    <ModalContainer top='45px'>
        <Header>
            <Heading>
                🎨 Data Format Settings
            </Heading>
            <IoIosCloseCircle style={{"cursor" : "pointer", "fontSize" : "18px","color":"#BEA281","margin-right": "-10px"}} onClick={handleCloseButton} />
        </Header>
        <Conditionals>
            <ConditionalHeading>Style Cells Matching Condition</ConditionalHeading>
            <CustomSelect onChange={(event) => { handleSelectOption(event) }} value={select}>
                <option value={"0"}>None</option>
                <option value={"1"}>Equal to A</option>
                <option value={"2"}>Not Equal to A</option>
                <option value={"3"}>Greater than A</option>
                <option value={"4"}>Less than A</option>
                <option value={"5"}>Greater than or equal to A</option>
                <option value={"6"}>Less than or equal to A</option>
                <option value={"7"}>Not less than A, not greater than B</option>
                <option value={"8"}>Less than A or greater than B</option>
            </CustomSelect>
            <LabelContainer>
                    <CustomLabel>{`A`}</CustomLabel>
                    <CustomLabel>{`B`}</CustomLabel>
                    </LabelContainer>
            <InputArea>
                <InputContainer>
                    <CustomInput 
                        onChange={(event) => { handleInputChange(event, "a") }} 
                        value={inputValues["a"] || undefined}
                        disabled={select === "0"}
                    />
                </InputContainer>
                <InputContainer>
                    <CustomInput 
                        onChange={(event) => { handleInputChange(event, "b") }} 
                        value={inputValues["b"] || undefined}
                        disabled={!(select === "7" || select === "8")}
                    />
                </InputContainer>
            </InputArea>
        </Conditionals>
        <ColorPalette cell_color={cellColor} handleColorChange={handleColorChange} />
        <ButtonContainer>
            <ActionButton backgroundColor={"#dc3545"} onClick={handleClearCondition}>
                Clear Condition
            </ActionButton>
            <ActionButton backgroundColor={"#4a86e2"} onClick={handleApplyButton}>
                Apply
            </ActionButton>
        </ButtonContainer>
    </ModalContainer>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConditionFormat);
