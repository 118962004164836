import { SET_CONDITIONAL_FILTER, SET_CONDITIONAL_FORMAT, SET_MODAL_VISIBILITY, SET_CONFIGURATION_WIDGET, SET_SELECTION_VIEW  } from '../Constants/actionTypes';
const merge = require('deepmerge');

// The state is designed such that it is able to store all the table controls including 
// conditional_format, conditional_filter, configurations_widget. There is also a show 
// key inside the state which is responsible for storing the current open modal. Its value 
// is equivalent to the modal/control_type which is currently open. Else the value is false.

const defaultState = {
    "conditional_format" : {
        "cell_color" : "#ffffff",
        "conditional_option" : "0",
        "conditional_value_a" : null,
        "conditional_value_b" : null
    },
    "conditional_filter" : {
        "cell_color" : "#ffffff",
        "conditional_option" : "0",
        "conditional_value_a" : null,
        "conditional_value_b" : null,
        "hide_conditionals" : {
            "hide_zeroes" : false,
            "hide_empty_fields" : false,
            "hide_non_numerics" : false
        }
    },
    "configurations_widget" : {
        appearance : "0",
        number_format : "0",
        number_decimal : "0",
        show_conditional_filter: true,
        show_conditional_format: true,
        show_toggle_columns: true,
        show_download: true
    },
    "show" : false,
    "view_selection" : {
        "chart": false,
        "table": true
    }
}

export default (state = defaultState , action ) => {
    let clonedState = merge({}, state);

    switch(action.type){
        case SET_CONDITIONAL_FILTER : {
            const { conditional_option, conditional_value_a, conditional_value_b, hide_conditionals } = action.payload;
            const clonedConditionals = merge({}, hide_conditionals);
            clonedState["conditional_filter"]["conditional_option"] = conditional_option;
            clonedState["conditional_filter"]["conditional_value_a"] = conditional_value_a;
            clonedState["conditional_filter"]["conditional_value_b"] = conditional_value_b;
            clonedState["conditional_filter"]["hide_conditionals"] = clonedConditionals;
            return clonedState
        }

        case SET_CONDITIONAL_FORMAT : {
            const { conditional_option, conditional_value_a, conditional_value_b, cell_color } = action.payload;
            clonedState["conditional_format"]["conditional_option"] = conditional_option;
            clonedState["conditional_format"]["conditional_value_a"] = conditional_value_a;
            clonedState["conditional_format"]["conditional_value_b"] = conditional_value_b;
            clonedState["conditional_format"]["cell_color"] = cell_color;
            return clonedState
        }

        case SET_MODAL_VISIBILITY : {
            const { control_type, show } = action.payload;
            if (show) {

                clonedState["show"] = control_type;
            }
            else{
                clonedState["show"] = show;
            }
            
            return clonedState
        }

        case SET_CONFIGURATION_WIDGET : {
            // Set all configuration options here = ["appearance", "number_format", "number_decimal"]
            const { appearance, number_format, number_decimal, show_conditional_filter, show_conditional_format, show_toggle_columns, show_download } = action.payload;
            clonedState["configurations_widget"]["appearance"] = appearance;
            clonedState["configurations_widget"]["number_format"] = number_format;
            clonedState["configurations_widget"]["number_decimal"] = number_decimal;
            clonedState["configurations_widget"]["show_conditional_filter"] = show_conditional_filter;
            clonedState["configurations_widget"]["show_conditional_format"] = show_conditional_format;
            clonedState["configurations_widget"]["show_toggle_columns"] = show_toggle_columns;
            clonedState["configurations_widget"]["show_download"] = show_download;
            return clonedState
        }

        case SET_SELECTION_VIEW : {
            // selection_view_type can be either chart or table
            const { selection_view_type, selection_view_value } = action.payload;

            // dont set view_selection for both chart and table to be false at the same time
            // if (!selection_view_value){
            //     if (!(state["view_selection"]["chart"] && state["view_selection"]["table"])){
            //         return clonedState
            //     }
            // }

            if (selection_view_type === 'table'){
                clonedState["view_selection"]['table'] = selection_view_value
                clonedState["view_selection"]['chart'] = !selection_view_value
            }
            else{
                clonedState["view_selection"]['table'] = !selection_view_value
                clonedState["view_selection"]['chart'] = selection_view_value
            }

            return clonedState
        }

        default :
            return state
    }
}
