import React, { Component } from "react";
import styled from 'styled-components';
import { connect } from "react-redux";

const ChartContainer = styled.div`
  display : ${props => props.isActive ? 'unset' : 'none'};
`;

const mapStateToProps = state => ({
    selections: state.selections,
    view_selection: state.table_controls.view_selection,
    datapackage : state.datapackage
});

class Charts extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { view_selection } = this.props;
        
        return (
            <ChartContainer isActive={view_selection.chart}>
                <img src="https://i.giphy.com/media/xT5LMDYj4kvKNlGDHq/200.webp" />
                <div>Coming Soon 😉</div>
            </ChartContainer>
        );
    }
}

export default connect(mapStateToProps)(Charts);
