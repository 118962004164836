import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useOutsideClick } from './utils'
import { SET_MODAL_VISIBILITY } from '../../Constants/actionTypes';

const Container = styled.div`
    border: 0.85px solid rgba(142, 142, 140, 0.335309);
    position: absolute;
    color: black;
    z-index: 100;
    background: linear-gradient(180deg, #FFFFFF 0%, #FBFBFB 100%);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.02);
    width: 250px;
    border-radius: 6px;
    div:not(:last-child) {
        border-right: none !important;
    }

    padding: ${props => props.padding ? props.padding : 'none'};

    @media (min-width: 426px){
        left: ${props => props.left ? props.left : 'none'};
        right: ${props => props.right ? props.right : 'none'};
        top: ${props => props.top ? props.top : 'none'};
        bottom: ${props => props.bottom ? props.bottom : 'none'};
    }
    
    @media (max-width: 425px) {
        left: 50%;
        transform: translateX(-50%);

        top: 60px;
    }
`;

const mapDispatchToProps = dispatch => ({
    setModalVisibility : (payload) => dispatch({ type : SET_MODAL_VISIBILITY, payload })
});

const ModalContainer = (props) => {
    
    const handleClickOutside = () => {
        props.setModalVisibility({
            show : false
        });
    };

    const ref = useOutsideClick(handleClickOutside);

    return (
        <Container ref={ref} {...props}>
            { props.children }
        </Container>
    )
}

export default connect(null, mapDispatchToProps)(ModalContainer);