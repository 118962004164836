import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const CustomButton = styled.div`
    border: 1px solid #C3B763;
    font-size: 15px;
    color: #A39135;
    letter-spacing: 0.3px;
    padding : 10px 16px;
    cursor: pointer;
    font-weight: 500;
    height: 20px;  
    display : flex;
    align-items: center;
    justify-content: center;

    background-image: ${props => props.isActive ? 'linear-gradient(180deg, #FFEF7E 0%, #EDDC6A 100%)' : 'none'};
    background-color: ${props => props.isActive ? 'none' : 'whitesmoke'};

    border-top-left-radius: ${props => props.borderOrientation === 'left' ? '5px' : 'none'};
    border-bottom-left-radius: ${props => props.borderOrientation === 'left' ? '5px' : 'none'};
    ${props => props.borderOrientation === 'left' 
        && css`
            border-right: 0px;
        `};

    border-top-right-radius: ${props => props.borderOrientation === 'right' ? '5px' : 'none'};
    border-bottom-right-radius: ${props => props.borderOrientation === 'right' ? '5px' : 'none'};

    ${props => props.rounded 
        && css`
            border-radius: 5px;
        `};

    :hover, :focus{
        outline: none
    }

    &.svg{
        cursor:pointer;
    }

    @media (max-width: 425px) {
        padding: 5px 8px;
        font-size: 12px;
    }
`;

export default CustomButton;