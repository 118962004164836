import React, { Component } from 'react';
import { getDatasets } from '../../API';

import DataSets from './DataSets.js';
import { SET_DATASET } from '../../Constants/actionTypes.js';
import { connect } from 'react-redux';
import styled from 'styled-components';

const mapStateToProps = state => ({
  selections : state.selections,
  utils : state.utils
});


const Container = styled.div`
  width : 25%;
  align-items: center;
  justify-content: center;
  text-align: left;
  @media only screen and (max-width: 768px) {
    width : 100%;
    margin-top: 20px;
  }
`;
const LeftContainer = styled.div`
  padding: 0 20px;
`;

const mapDispatchToProps = dispatch => ({
  setSelections : (payload) => dispatch({ type : SET_DATASET, payload }),
});


class LeftBar extends Component{

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    this.fetchData();
  }

  fetchData = async () => {
    const { datasets } = await getDatasets();

    this.props.setSelections(datasets);
  }

  render() {
    return (
      <Container>
        <LeftContainer>
          <DataSets selectionType={"datasets"} />
        </LeftContainer>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar);
