import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { createReduxHistoryContext, reachify } from "redux-first-history";
import reducer from './Reducers/index.js';
import { createLogger } from 'redux-logger';

const history = createBrowserHistory();

const { routerMiddleware } = createReduxHistoryContext({ 
  history: history
});

const getMiddlewares = () => {
    if (process.env.NODE_ENV === 'production') {
        return applyMiddleware(routerMiddleware);
    }
    else {
        return applyMiddleware(routerMiddleware, createLogger());
    }
};

const configureStore = (preloadedState) => {
  const store = createStore(
    reducer(history), // root reducer with router state
    preloadedState,
    compose( getMiddlewares() )
  )

  return store
}

export { history, configureStore }