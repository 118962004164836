import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import CustomButton from './CustomButton.js';

import { SET_MODAL_VISIBILITY } from '../../Constants/actionTypes';

const Container = styled.div`
    color: ${props => props.isActive ? '#847109' : '#bfbfbf'};   
    
    @media (min-width: 426px) {
        position: relative;
    }
`;

const mapStateToProps = state => ({
    table_controls : state.table_controls
});

const mapDispatchToProps = dispatch => ({
    setModalVisibility : (payload) => dispatch({ type : SET_MODAL_VISIBILITY, payload })
});

class ButtonWithModal extends Component{

	constructor(props) {
		super(props);
        
        this.handleOnClick = this.handleOnClick.bind(this);
    }
    
    handleOnClick = () => {
        const { control_type } = this.props;

        this.props.setModalVisibility({
            control_type : control_type,
            show : true
        });
    }

	render(){
        const { children, label, isActive, table_controls, control_type, borderOrientation, rounded } = this.props;
        const show = table_controls["show"] === control_type;
        
        return (
            <Container>
                <CustomButton isActive={isActive} onClick={ this.handleOnClick } borderOrientation={borderOrientation} rounded={rounded}>
                    { 
                        label ? label : this.props.icon
                    }
                </CustomButton>
                {
                    show ? children : null
                }
            </Container>
        )
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonWithModal);

