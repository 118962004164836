import DataFrame from "dataframe-js";
import Data from './Data.js';

const getDataflow = (dataset_id) => {
  return new Promise((resolve, reject) => {
    resolve(Data[dataset_id])
  })
};

const getDatasets = () => {
  return { datasets : [
    {
      "id" : 1,
      "name" : "💸  Startup Investments"
    },
    {
      "id" : 2,
      "name" : "🟢  Pokemon Stats"
    }
  ]}
};

const dataframeFromDataflow = (dataflow, shouldConvertEnums = true) => {
  const valueAttr = "OBS_VALUE";
  let table = [];

  // Columns
  const dimensions = dataflow.structure.dimensions;
  let columns = dimensions.series.map(v => v.id);
  const obsCols = dimensions.observation.map(v => v.id);
  columns = columns.concat(obsCols);
  columns.push(valueAttr);

  // Row
  const series = Object.entries(dataflow.dataSets[0].series);
  series.forEach(([seriesKey, seriesValue]) => {
    let baseRow = seriesKey.split(":");

    if (shouldConvertEnums) {
      baseRow = baseRow.map(
        (value, index) => dimensions.series[index].values[value].name
      );
    }

    Object.entries(seriesValue.observations).forEach(([key, value]) => {
      let row = baseRow.slice();

      if (shouldConvertEnums) {
        key = dimensions.observation[0].values[key].name;
      }

      row.push(key);
      row.push(value[0]);
      table.push(row);
    });
  });

  let dataframe = new DataFrame(table, columns);
  dataframe = dataframe.cast(valueAttr, Number);
  return dataframe;
};

const transformDataframe = (dataframe, index, columns, filters) => {
  dataframe = filterDataframe(dataframe, filters);
  return pivotDataframe(dataframe, index, columns);
};

const filterDataframe = (dataframe, filters = {}) => {
  const operations = Object.entries(filters).map(([col, values]) => {
    const data_values = values.map((each_value) => { return each_value.value });
    return row => {
      if (row.has(col) && data_values.length > 0 && row.get(col) && row.get(col) != "-" ) {
        return data_values.indexOf(row.get(col)) > -1;
      }
      return true;
    }
  });

  return dataframe["chain"](...operations);
};

const pivotDataframe = (dataframe, index = [], columns = []) => {
  const value = "OBS_VALUE";
  const hasSelectedIndex = index && index.length;
  const hasSelectedColumns = columns && columns.length;

  let agg = new Map();
  const aggKeys = index.concat(columns).filter(x => x);
  const groupedDF = dataframe["groupBy"](...aggKeys);
  let rowKey = value;
  let colKey = value;
  groupedDF.aggregate(group => {
    const row = group.getRow(0);
    if (hasSelectedIndex) {
      rowKey = row
        .select(...index)
        .toArray()
        .join("_");
    }
    const baseAttrs = Object.fromEntries(index.map(i => [i, row.get(i)]));
    agg.set(rowKey, agg.get(rowKey) || baseAttrs);
    if (hasSelectedColumns) {
      colKey = row
        .select(...columns)
        .toArray()
        .join("_");
    }
    agg.get(rowKey)[colKey] = group.stat.sum(value);
  });
  return new DataFrame(Array.from(agg.values()));
};

export {
  dataframeFromDataflow,
  getDataflow,
  getDatasets,
  pivotDataframe,
  transformDataframe,
  filterDataframe
};
