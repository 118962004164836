import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { FaDownload, FaShareAlt, FaCog, FaFilter } from "react-icons/fa";
import { MdFormatPaint } from "react-icons/md";
import ButtonWithModal from './ButtonWithModal.js';
import ConditionFormat from './ConditionFormat.js';
import ConditionFilter from './ConditionFilter.js';
import ShareModal from './shareModel.js';
import ConfigurationWidget from './ConfigurationWidget.js';
import DownloadDataPackage from './DownloadDataPackage.js';
import { connect } from 'react-redux';
import { SET_SELECTION_VIEW } from '../../Constants/actionTypes';
import CustomButton from './CustomButton.js';
import TableIcon from '../Assets/TableIcon.js';
import ChartIcon from '../Assets/ChartIcon.js';

const mapDispatchToProps = dispatch => ({
    setSelectionView : (payload) => dispatch({ type : SET_SELECTION_VIEW, payload })
});

const mapStateToProps = (state) => ({
    configurations_widget : state.table_controls.configurations_widget,
    view_selection: state.table_controls.view_selection
});

const Container = styled.div`
    border-radius: 6px;
    padding : 30px 0px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 425px) {
        padding : 20px 0px 10px 0px;
        position: relative;
    }
`;

const ButtonGroup = styled.div`
    display: flex;

    @media only screen and (max-width: 768px) {
		margin-bottom : 10px;
    }
`;

const DropdownContainer = styled.div`
    display: none;
    position: absolute;
    right:0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
`;

const Dropdown = styled.div`
    position: relative;
    display: inline-block;
    &:hover{
        & > ${DropdownContainer} {
            display: block;
        }
    }
  }`;

class TableControls extends Component{
    
    switchSelection = (selection_view_type) => {
        const selection_view_value = this.props.view_selection[selection_view_type];

        this.props.setSelectionView({ 
            selection_view_type : selection_view_type,
            selection_view_value : !selection_view_value
        });
    } 

	render(){
        const { view_selection, configurations_widget } = this.props;
        const { show_conditional_filter, show_conditional_format, show_download }  = configurations_widget;
        const isMobile = window.innerWidth <= 425;

        return (
            <Container>
                {/* <ButtonGroup>
                    <CustomButton isActive={view_selection.table} onClick={() => this.switchSelection("table")} borderOrientation={'left'}>
                        {
                            isMobile ? <TableIcon width={"14px"} height={"46px"} fill={"#A39135"} /> : "Table" 
                        }
                    </CustomButton>
                    <CustomButton isActive={view_selection.chart} onClick={() => this.switchSelection("chart")} borderOrientation={'right'}>
                        {
                            isMobile ? <ChartIcon width={"14px"} height={"14px"} fill={"#A39135"} /> : "Charts" 
                        }
                    </CustomButton>
                </ButtonGroup> */}
                <ButtonGroup>
                    {
                        isMobile ? (
                            <Fragment>
                                {
                                    show_conditional_filter ? (
                                        <ButtonWithModal isActive={true} icon={<FaFilter />} control_type={"conditional_filter"} borderOrientation={'left'}>
                                            <ConditionFilter/>
                                        </ButtonWithModal>
                                    ) : null
                                }
                                {
                                    show_conditional_format ? (
                                        <ButtonWithModal isActive={true} icon={<MdFormatPaint />} control_type={"conditional_format"} borderOrientation={'right'}>
                                            <ConditionFormat/>
                                        </ButtonWithModal>
                                    )
                                    :
                                    null
                                }
                            </Fragment>
                        ) : 
                        (   
                            <Fragment>
                                {
                                    show_conditional_filter ? ( 
                                        <ButtonWithModal isActive={true} label={"Condition Filters"} control_type={"conditional_filter"} borderOrientation={'left'}>
                                            <ConditionFilter/>
                                        </ButtonWithModal>
                                    ) : null
                                }
                                {
                                    show_conditional_format ? (
                                        <ButtonWithModal isActive={true} label={"Condition Format"} control_type={"conditional_format"} borderOrientation={'right'}>
                                            <ConditionFormat/>
                                        </ButtonWithModal>
                                    ) : null
                                }
                            </Fragment>
                        )
                    }
                </ButtonGroup>
                <ButtonGroup>
                    {
                        show_download ? (
                            <Dropdown isActive={true}>
                                <CustomButton isActive={true} borderOrientation={'left'}><FaDownload /></CustomButton>
                                <DropdownContainer>
                                    <DownloadDataPackage format="csv" />
                                    <DownloadDataPackage format="json" />
                                </DropdownContainer>
                            </Dropdown>
                        )
                        :
                        null
                    }
                    <ButtonWithModal isActive={true} icon={<FaCog />} control_type={"configurations_widget"} borderOrientation={'right'}>
                        <ConfigurationWidget />
                    </ButtonWithModal>
                </ButtonGroup>
            </Container>
        )
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(TableControls);


{/* <ButtonGroup>
    <CustomButton isActive={true}>S</CustomButton>
    <CustomButton isActive={true}>R</CustomButton>
    <CustomButton isActive={true}>I</CustomButton>
</ButtonGroup> */}


{/* <CustomButton isActive={true}><img src={"/bookmark.svg"} alt="bookmark"/></CustomButton>
<ButtonWithModal isActive={true} icon= {<FaShareAlt />} control_type={"share_modal"}>
    <ShareModal/>
</ButtonWithModal> */}