import { SET_DATASET_ID, SET_DIMENSIONS, SET_DIMENSION_VALUES, SET_FILTERS, SET_MULTIPLE_DIMENSIONS, SET_DATASET, UPDATE_DIMENSIONS } from '../Constants/actionTypes';
const merge = require('deepmerge');

const defaultState = {
    // Stores data from the items selected in <LeftBar />
    "datasets" : {
        "selected_id" : null,
        "options" : {}
    },
    "dimensions" : {
        "columns" : [],
        "filters" : [],
        "values" : {}
    },
    "filters" : {}
}

export default (state = defaultState , action ) => {
    let clonedState = merge({}, state);

    switch(action.type){
        case SET_DATASET : {
            const selections = action.payload;
            selections.forEach((selection) => {
                const id = selection["id"];
                clonedState["datasets"]["options"][id] = selection;
                return id
            });
            return clonedState;
        }

        case SET_DATASET_ID : {
            const { selected_id } = action.payload;
            clonedState["datasets"]["selected_id"] = selected_id;

            clonedState["dimensions"]["columns"] = [];
            clonedState["dimensions"]["filters"] = [];
            clonedState["dimensions"]["values"] = [];
            
            clonedState["filters"]["values"] = [];
            return clonedState
        }

        case SET_DIMENSIONS : {
            const dimension_type = action.payload.dimension_type;
            const selections = action.payload.selections;
            clonedState["dimensions"][dimension_type] = selections;
            return clonedState
        }

        case SET_MULTIPLE_DIMENSIONS : {
            const { selections_map } = action.payload;
            const dimension_types = Object.keys(selections_map);

            dimension_types.forEach((dimension_type) => {
                const selections = selections_map[dimension_type].filter(obj => Boolean(obj));
                clonedState["dimensions"][dimension_type] = selections;

            });

            return clonedState
        }

        case SET_DIMENSION_VALUES : {
            const { dimensions } = action.payload;
            const dimension_values = {};
            const filtered_values = {};

            dimensions.forEach((dimension) => {
                const id = dimension.id;
                dimension_values[id] = dimension;

                filtered_values[id] = dimension["values"];
            })

            clonedState["dimensions"]["values"] = dimension_values;

            clonedState["filters"] = filtered_values;
            return clonedState
        }

        case UPDATE_DIMENSIONS : {
            const { dimension_type, selections, operation_type } = action.payload;
            let modifiedDimension;

            if (operation_type === "add"){
                modifiedDimension = clonedState["dimensions"][dimension_type].concat(selections);
            }
            else{
                const removable_selections = selections.map((selection)=>{
                    return `${selection["dataset_id"]}-${selection["id"]}`;
                });

                modifiedDimension = clonedState["dimensions"][dimension_type].filter((dimension) => {
                    const unique_key = `${dimension["dataset_id"]}-${dimension["id"]}`;
                    if (removable_selections.includes(unique_key)){
                        return false
                    }
                    else{
                        return true
                    }
                });
            }


            const uniqueDimensionIDS = new Set();
            clonedState["dimensions"][dimension_type] = modifiedDimension.filter((dimension)=>{
                if (uniqueDimensionIDS.has(dimension.id)){
                    return false
                }
                else{
                    uniqueDimensionIDS.add(dimension.id)
                    return true
                }
            })

            return clonedState
        }

        case SET_FILTERS : {
            const { values, id } = action.payload;

            clonedState["filters"][id] = values;

            return clonedState
        }

        default :
            return state
    }
}
