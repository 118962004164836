import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Table from './Table.js';
import TableControls from './TableControls.js';
import DimensionsTable from './DimensionsTable';
import Charts from './Charts.js';

const Container = styled.div`
	width : 75%;
	@media only screen and (max-width: 768px) {
		width : 100%;
	} 
`;
const RightContainer = styled.div`
	padding: 0 20px;
`;

const mapStateToProps = state => ({
    configurations_widget : state.table_controls.configurations_widget
});

const RightBar = ({ configurations_widget }) => {
	const { show_toggle_columns }  = configurations_widget;
	return (
		<Container>
			<RightContainer>
				<TableControls />
				{ show_toggle_columns ? <DimensionsTable /> : null }
				<Table />
				<Charts />
			</RightContainer>
		</Container>
	)
}

export default connect(mapStateToProps, null)(RightBar);